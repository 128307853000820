<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      v-if="!template.isSceleton"
      :elevation="hover ? 6 : 2"
      width="330"
      min-height="190"
      max-height="200"
      outlined
      :disabled="deactivated"
      class="my-2 ma-2"
      @click="openOverview"
    >
      <div class="row">
        <v-card-title
          v-if="template.name.length >= 18"
          class="d-flex align-center ml-2 mt-2"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="font-weight-bold"
                style="font-size: 18px;"
                v-on="on"
              >
                {{ template.name.slice(0, 18) + '...' }}
              </div>
            </template>
            <span>{{ template.name }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-title
          v-else
          class="d-flex align-center ml-2 mt-2 font-weight-bold"
          style="font-size: 18px;"
        >
          {{ template.name }}
        </v-card-title>
        <v-spacer />
        <v-menu
          open-on-hover
          offset-y
          min-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="primary"
              :class="account.accountType === 'god' ? 'mr-5' : 'ml-2'"
              class="mt-n1"
              v-bind="attrs"
              v-on="on"
            >
              mdi-account-group
            </v-icon>
          </template>
          <v-list
            dense
            class="pa-3"
          >
            <span
              class="ml-2"
              style="font-size: 15px;"
            >
              {{ $t('common|created_by') }}:
            </span>
            <v-list-item v-if="template.onCreatedBy === 'Account' && template.createdBy.accountType !== 'god'">
              <v-list-item-icon>
                <v-avatar
                  size="30px"
                  class="ml-1 mr-1"
                >
                  <img
                    v-if="template.createdBy && template.createdBy.avatar"
                    alt="Avatar"
                    :src="`${cfg.baseURL}downloadAvatar/${template.createdBy._id}/${template.createdBy.avatar.raw.filename}`"
                  >
                  <v-avatar
                    v-else
                  >
                    <v-icon
                      color="primary"
                    >
                      mdi-account
                    </v-icon>
                  </v-avatar>
                </v-avatar>
                <span
                  class="text-truncate ml-2 mt-1"
                  style="font-size: 15px;"
                >
                  {{ getName(template.createdBy) }}
                </span>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item v-if="template.createdBy.accountType === 'god'">
              <v-list-item-icon>
                <img
                  class="logo"
                  src="@/assets/Lavvira_monogramm_logo.svg"
                  height="22px"
                  width="auto"
                >
              </v-list-item-icon>
              <span
                class="ml-n6"
              >
                Lavvira
              </span>
            </v-list-item>
            <v-list-item v-if="template.onCreatedBy === 'Company'">
              <v-list-item-icon class="ml-2">
                <v-icon
                  color="primary"
                >
                  mdi-domain
                </v-icon>
              </v-list-item-icon>
              <span
                style="font-size: 15px;"
                class="ml-n4"
              >
                {{ template.createdBy.companyData.companyName }}
              </span>
            </v-list-item>
            <v-list-item v-if="template.onCreatedBy === 'Group'">
              <v-list-item-icon class="ml-2">
                <v-icon
                  color="primary"
                >
                  mdi-domain
                </v-icon>
              </v-list-item-icon>
              <span
                style="font-size: 15px;"
                class="ml-n4"
              >
                {{ template.createdBy.groupName }}
              </span>
            </v-list-item>
            <span
              class="ml-2"
              style="font-size: 15px;"
            >
              {{ $t('common|shared_with') }}:
            </span>
            <v-list-item
              v-for="acc in template.sharedWith.accounts"
              :key="acc._id"
            >
              <v-avatar
                size="30px"
                class="ml-1 mr-1"
              >
                <img
                  v-if="acc && acc.avatar"
                  alt="Avatar"
                  :src="`${cfg.baseURL}downloadAvatar/${acc._id}/${acc.avatar.raw.filename}`"
                >
                <v-avatar
                  v-else
                >
                  <v-icon
                    color="primary"
                    small
                  >
                    mdi-account
                  </v-icon>
                </v-avatar>
              </v-avatar>
              <v-list-item-title
                v-if="acc.accountType !== 'god' && acc.accountData"
                class="ml-2"
              >
                {{ getName(acc) }}
              </v-list-item-title>
              <v-list-item-title
                v-else
                class=""
              >
                {{ $t('mixed|all_lavvira_users') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="c in template.sharedWith.company"
              :key="c._id"
              class="ml-2"
            >
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-domain
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-n4">
                {{ c.companyData.companyName }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(g, index) in template.sharedWith.groups"
              :key="index"
            >
              <v-list-item-icon class="ml-2">
                <v-icon color="primary">
                  mdi-account-group
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="ml-n4">
                {{ g.groupName }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="!account.comapnyId ? on : ''">
              <v-btn
                v-if="account.accountType !== 'god' && template && !template.shared"
                icon
                color="primary"
                :disabled="disabledRule"
                class="mt-5 mr-n2"
                v-bind="attrs"
                @click.stop="shareTemplate(template, actualTab)"
                v-on="on"
              >
                <v-icon
                  size="22"
                >
                  mdi-share-variant
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ account.companyId ? $t('actions|share') : $t('expressions|activate_members_plan_to_share') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                v-if="account.accountType === 'god' && !template.shared"
                icon
                color="primary"
                class="mt-5 mr-5 ml-n4"
                v-bind="attrs"
                @click.stop="shareLavviraTemplate(template)"
                v-on="on"
              >
                <v-icon
                  size="22"
                >
                  mdi-share-variant
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('actions|share') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                v-if="account.accountType === 'god' && template.shared"
                icon
                class="mt-5 mr-5 ml-n4"
                v-bind="attrs"
                @click.stop="unShareLavviraTemplate(template)"
                v-on="on"
              >
                <v-icon
                  size="22"
                  color="error"
                >
                  mdi-share-off
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('actions|stop_sharing') }}</span>
        </v-tooltip>
        <div v-if="account.accountType !== 'god'">
          <div v-if="template.pinned.includes(account._id)">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-4 mt-5"
                  v-bind="attrs"
                  @click.stop="removePinFromDashboard(template)"
                  v-on="on"
                >
                  <v-icon>
                    mdi-pin
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('actions|remove_from_pinboard') }}</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-4 mt-5"
                  v-bind="attrs"
                  @click.stop="pinToDashboard(template)"
                  v-on="on"
                >
                  <v-icon>
                    mdi-pin-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('actions|add_to_pinboard') }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <v-card-text
        class="mt-n8"
      >
        <div>
          {{ $t('common|category') }}: {{ translateCategoryIfNeeded(template.category) }}
        </div>
        <div>
          <v-menu
            open-on-hover
            bottom
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                :id="`templateDescription_${templateCardIndex}`"
                class="text-truncate"
                :style="`max-width: 300px; font-family: ${getBodyFontFam()} !important;`"
                v-bind="attrs"
                v-on="isEllipsisActive(`templateDescription_${templateCardIndex}`) ? on : ''"
              >
                {{ $t('common|description') }}: {{ template.description }}
              </div>
            </template>

            <v-card
              max-width="350"
            >
              <v-card-text>
                {{ template.description }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <div>
          {{ $t('common|date_created') }}: {{ template.createdAt }}
        </div>
      </v-card-text>
      <v-card-actions style="position: absolute; bottom: 0px;">
        <v-btn
          color="primary"
          outlined
          rounded
          small
          class="ml-n2"
          @click.stop="template.shared && account.accountType !== 'god' ? previewTemplate(template) : viewTemplate(template)"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-arrow-top-right
          </v-icon>
          {{ template.shared && account.accountType !== 'god' ? 'Preview' : $t('actions|open') }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          rounded
          small
          @click.stop="useTemplateDialog = true"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-link
          </v-icon>
          {{ $t('actions|use') }}
        </v-btn>
      </v-card-actions>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            style="position: absolute; right: 5px; bottom: 5px;"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteTemplateAction(template)"
          >
            <v-icon
              size="20"
            >
              mdi-trash-can
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions|delete_template') }}</span>
      </v-tooltip>
      <v-dialog
        v-model="useTemplateDialog"
        keydown
        persistent
        max-width="800px"
      >
        <UseTemplateDialog
          :template="template"
          @closeDialog="closeDialog"
        />
      </v-dialog>
    </v-card>
    <v-skeleton-loader
      v-else
      :elevation="hover ? 6 : 2"
      width="320"
      min-height="190"
      max-height="200"
      class="my-2 ma-2"
      type="card-heading, list-item-three-line, actions"
    />
  </v-hover>
</template>

<script>
import UseTemplateDialog from '../../components/dialogs/UseTemplateDialog.vue'
import TemplateCardMixin from './TemplateCardMixin'
import generalMixin from '@/utils/generalMixin'

export default {
  components: { UseTemplateDialog },
  mixins: [ TemplateCardMixin, generalMixin ],
  props: {
    template: {
      type: Object,
      default: null
    },
    group: {
      type: Object,
      default: null
    },
    // company: {
    //   type: Object,
    //   default: null
    // },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    },
    deactivated: {
      type: Boolean,
      default: false
    },
    templateCardIndex: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      useTemplateDialog: false
    }
  }
}
</script>
